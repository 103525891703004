import { StoreProvider } from 'easy-peasy';
import {
  FunctionComponent, lazy, useEffect, useMemo, useState,
} from 'react';
import { Outlet, RouteObject, ScrollRestoration } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGTM, useLoadingRequest, useAsyncRequest } from 'hooks';
import { AppLoader } from 'components/loaders';
import {
  layout,
  route,
  page,
  indexed,
  t,
  layoutroute,
} from 'libs';
import { AppError } from 'components/errors';
import { Main } from 'components/containers';

import store, { useClientActions, useClientState } from './store';
import { PATHS } from './libs';
import {
  Public,
  Access,
  Dashboard,
  Plain,
} from './layouts';
import { Authentication, Private } from './features/authentication';
import { useContractsRequest } from './features/contracts';
import { usePrivateAsyncRequest } from './hooks';
import { RequestLoader } from './features/requests';
import { SelectedContractProvider } from './contexts/SelectedContractContext';

const Home = lazy(() => import('./views/Home'));
const ElectricalSupply = lazy(() => import('./views/electrical-supply/ElectricalSupply'));
const RegistrationsWizard = lazy(() => import('./views/electrical-supply/RegistrationsWizard'));
const CupsProcess = lazy(() => import('./views/electrical-supply/CupsProcess'));
const SupplyProcess = lazy(() => import('./views/electrical-supply/SupplyProcess'));
const Selfconsumption = lazy(() => import('./views/selfconsumption/Selfconsumption'));
const SelfconsumptionProcess = lazy(() => import('./views/selfconsumption/SelfconsumptionProcess'));
const EnergyGeneration = lazy(() => import('./views/EnergyGeneration'));
const NodalCapcities = lazy(() => import('./views/NodalCapacities'));
const Normative = lazy(() => import('./views/Normative'));
const Contact = lazy(() => import('./views/contact/Contact'));
const Message = lazy(() => import('./views/contact/Message'));
const Fraud = lazy(() => import('./views/contact/Fraud'));
const Incidents = lazy(() => import('./views/contact/Incidents'));
const Call = lazy(() => import('./views/contact/Call'));
const Session = lazy(() => import('./views/Session'));
const Register = lazy(() => import('./views/Register'));
const Welcome = lazy(() => import('./views/Welcome'));
const EmailValidation = lazy(() => import('./views/EmailValidation'));
const ResendEmail = lazy(() => import('./views/ResendEmail'));
const ResetPassword = lazy(() => import('./views/passwords/ResetPassword'));
const RestorePassword = lazy(() => import('./views/passwords/RestorePassword'));
const Profile = lazy(() => import('./views/profile/Profile'));
const EditProfile = lazy(() => import('./views/profile/EditProfile'));
const VerifyProfile = lazy(() => import('./views/profile/VerifyProfile'));
const Contract = lazy(() => import('./views/contracts/Contract'));
const NewContract = lazy(() => import('./views/contracts/NewContract'));
const NewAuthorization = lazy(() => import('./views/contracts/NewAuthorization'));
const AddedAuthorized = lazy(() => import('./views/contracts/AddedAuthorized'));
const Breakdowns = lazy(() => import('./views/breakdowns/Breakdowns'));
const BreakdownsAll = lazy(() => import('./views/breakdowns/BreakdownsAll'));
const Requests = lazy(() => import('./views/requests/Requests'));
const AllRequests = lazy(() => import('./views/requests/AllRequests'));
const Request = lazy(() => import('./views/requests/Request'));
const Appointment = lazy(() => import('./views/requests/Appointment'));
const NewCauRequest = lazy(() => import('./views/requests/NewCauRequest'));
const NewCupsRequest = lazy(() => import('./views/requests/NewCupsRequest'));
const NewSupplyRequest = lazy(() => import('./views/requests/NewSupplyRequest'));
const NewSupplyCupsRequest = lazy(() => import('./views/requests/NewSupplyCupsRequest'));
const NewSelfconsumptionRequest = lazy(() => import('./views/requests/NewSelfconsumptionRequest'));
const NewGenerationRequest = lazy(() => import('./views/requests/NewGenerationRequest'));
const Consumption = lazy(() => import('./views/consumption/Consumption'));
const Energy = lazy(() => import('./views/consumption/Energy'));
const Power = lazy(() => import('./views/consumption/Power'));
const Reactive = lazy(() => import('./views/consumption/Reactive'));
const TipsToSave = lazy(() => import('./views/consumption/TipsToSave'));
const Meter = lazy(() => import('./views/meter/Meter'));
const ProgrammingMeasurement = lazy(() => import('./views/meter/ProgrammingMeasurement'));
const MeterReadingDetails = lazy(() => import('./views/meter/MeterReadingDetails'));
const Tariffs = lazy(() => import('./views/consumption/Tariffs'));
const IndustrialTariffs = lazy(() => import('./views/consumption/IndustrialTariffs'));
const DailyLoader = lazy(() => import('./views/consumption/DailyLoader'));
const DailyEnergy = lazy(() => import('./views/consumption/DailyEnergy'));
const ReactivePenalty = lazy(() => import('./views/consumption/ReactivePenalty'));
const PowerTariffs = lazy(() => import('./views/consumption/PowerTariffs'));

const ClientSession: FunctionComponent = () => {
  const update = useClientActions((actions) => actions.session.update);
  const selectables = useClientActions((actions) => actions.company.indexSelectables);
  const initAdvice = useClientActions((actions) => actions.app.initAdvice);
  const [loading, message] = useLoadingRequest(update);
  useContractsRequest();
  usePrivateAsyncRequest(selectables);
  useEffect(() => {
    initAdvice();
  }, []);

  return (
    <AppLoader loading={loading} message={t('clients.loading')}>
      <AppError message={message}>
        <Outlet />
      </AppError>
    </AppLoader>
  );
};

const ClientsLoader: FunctionComponent = () => {
  const index = useClientActions((actions) => actions.company.index);
  const company = useClientState((state) => state.company.item);
  const locations = useClientActions((actions) => actions.locations.index);
  const distributors = useClientActions((actions) => actions.distributor.index);
  const [loading, message] = useLoadingRequest(index);
  useGTM(company.gtm);
  useAsyncRequest(locations);
  useAsyncRequest(distributors);

  const contracts = useClientState((state) => state.contracts.validateds);
  const [selectedContractId, setSelectedContractId] = useState<string>('');

  const selectedContract = useMemo(
    () => contracts.find((c) => c.id === selectedContractId),
    [contracts, selectedContractId],
  );

  const selectedContractContextValue = useMemo(() => ({
    cupsType: selectedContract?.cupsType,
    contractId: selectedContractId,
    setSelectedContractId,
  }), [selectedContract?.cupsType, selectedContractId, setSelectedContractId]);

  return (
    <AppLoader loading={loading} message={t('clients.loading')}>
      <AppError message={message}>
        <SelectedContractProvider value={selectedContractContextValue}>
          <Main>
            <style>{`:root { ${company.system} }`}</style>
            <Helmet>
              <title>{company.name}</title>
              <meta name="description" content={company.text('banner')} />
              <meta property="og:title" content={company.name} />
              <meta name="og:description" content={company.text('banner')} />
              <meta property="og:image" content={company.document('brand')} />
              <link rel="icon" href={company.document('favicon')} />
            </Helmet>
            <ClientSession />
          </Main>
        </SelectedContractProvider>
      </AppError>
    </AppLoader>
  );
};

const ClientsApp: FunctionComponent = () => (
  <StoreProvider store={store}>
    <ScrollRestoration />
    <ClientsLoader />
  </StoreProvider>
);

const ClientsRouter: RouteObject[] = [
  layout(
    <ClientsApp />,
    route(
      PATHS.index,
      layout(
        <Public />,
        indexed(<Home />),
        route(
          PATHS.supply.index,
          indexed(<ElectricalSupply />),
          page(PATHS.supply.wizard, <RegistrationsWizard />),
          page(PATHS.supply.cups, <CupsProcess />),
          page(PATHS.supply.supply, <SupplyProcess />),
        ),
        route(
          PATHS.selfconsumption.index,
          indexed(<Selfconsumption />),
          page(PATHS.selfconsumption.procedures, <SelfconsumptionProcess />),
        ),
        page(PATHS.generation, <EnergyGeneration />),
        page(PATHS.nodal, <NodalCapcities />),
        page(PATHS.normative, <Normative />),
        route(
          PATHS.contact.index,
          indexed(<Contact />),
          page(PATHS.contact.message, <Message />),
          page(PATHS.contact.fraud, <Fraud />),
          page(PATHS.contact.incidents, <Incidents />),
          page(PATHS.contact.call, <Call />),
        ),
      ),
      layout(
        <Authentication />,
        layout(
          <Access />,
          page(PATHS.session, <Session />),
          page(PATHS.password.reset, <ResetPassword />),
          page(PATHS.password.restore, <RestorePassword />),
          page(PATHS.welcome, <Welcome />),
          page(PATHS.validation, <EmailValidation />),
          page(PATHS.revalidate, <ResendEmail />),
        ),
        layout(
          <Plain />,
          page(PATHS.register, <Register />),
        ),
      ),
      layout(
        <Private />,
        route(
          PATHS.profile.index,
          layout(
            <Dashboard />,
            indexed(<Profile />),
            page(PATHS.profile.validation, <VerifyProfile />),
          ),
          layout(
            <Plain />,
            page(PATHS.profile.edit, <EditProfile />),
          ),
        ),
        layout(
          <Dashboard />,
          route(
            PATHS.breakdowns.index,
            indexed(<Breakdowns />),
            page(PATHS.breakdowns.all, <BreakdownsAll />),
          ),
          page(PATHS.contracts.show, <Contract />),
          page(PATHS.contracts.new, <NewContract />),
          page(PATHS.authorized.added, <AddedAuthorized />),
          route(
            PATHS.consumption.index,
            layout(
              <Consumption />,
              indexed(<Energy />),
              page(PATHS.consumption.power, <Power />),
              page(PATHS.consumption.reactive, <Reactive />),
            ),
            page(PATHS.consumption.tariffs, <Tariffs />),
            page(PATHS.consumption.powerTariffs, <PowerTariffs />),
            page(PATHS.consumption.industrialTariffs, <IndustrialTariffs />),
            page(PATHS.consumption.reduce, <TipsToSave />),
            page(PATHS.consumption.penalties, <ReactivePenalty />),
            layout(
              <DailyLoader />,
              page(PATHS.consumption.dailyConsumption, <DailyEnergy />),
            ),
          ),
          route(
            PATHS.meter.index,
            indexed(<Meter />),
            page(PATHS.meter.programming, <ProgrammingMeasurement />),
            page(PATHS.meter.readingDetails, <MeterReadingDetails />),
          ),
        ),
        layout(
          <Plain />,
          page(PATHS.authorized.new, <NewAuthorization />),
        ),
        route(
          PATHS.requests.index,
          layout(
            <Dashboard />,
            indexed(<Requests />),
            page(PATHS.requests.all, <AllRequests />),
            layout(
              <RequestLoader />,
              layoutroute(
                PATHS.requests.show.index,
                <Request />,
                page(PATHS.requests.show.appointment, <Appointment />),
              ),
            ),
          ),
          layout(
            <Plain />,
            page(PATHS.requests.cau, <NewCauRequest />),
            page(PATHS.requests.cups, <NewCupsRequest />),
            page(PATHS.requests.supply, <NewSupplyRequest />),
            page(PATHS.requests.supplycups, <NewSupplyCupsRequest />),
            page(PATHS.requests.selfconsumption, <NewSelfconsumptionRequest />),
            page(PATHS.requests.generation, <NewGenerationRequest />),
          ),
        ),
      ),
    ),
  ),
];

export default ClientsRouter;
