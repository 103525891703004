import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

  // Define the structure of the concentrator data
  // Adjust this interface based on the actual data structure you receive
  interface ConcentratorData {
    // Add properties based on the response from your Go backend
    // For example:
    cups: string;
    // Add other properties as needed
  }

  interface IcpModel {
    concentratorData: ConcentratorData | null;

    setConcentratorData: Action<IcpModel, ConcentratorData>;

    fetchConcentratorData: Thunk<IcpModel, string>; // The parameter is the CUPS
  }

const icpModel: IcpModel = {
  concentratorData: null,

  setConcentratorData: action((state, payload) => {
    state.concentratorData = payload;
  }),

  fetchConcentratorData: thunk(async (actions, cups) => {
    const response = await API.get(clients)(`/icp/concentrator-data/${cups}`);
    return handleResponse(response, actions.setConcentratorData);
  }),
};

export type { IcpModel, ConcentratorData };

export default icpModel;
