import _ from 'lodash/fp';
import { momentum } from 'libs';

import {
  Metric, CalcMetric, MetricsGraphicData, Metrics,
} from './types';

const calcMetrics = _.map((metric: Metric): CalcMetric => ({
  ...metric,
  created_at: momentum(metric.created_at),
  limit_date: metric.limit_date ? momentum(metric.limit_date) : null,
  finish_date: metric.finish_date ? momentum(metric.finish_date) : null,
}));

const filterMetricsByYear = (
  data: Metric[],
  year: number,
): Metric[] => _.flow(
  _.filter((metric: Metric) => momentum(metric.created_at).year() === year),
)(data);

const buildMetrics = (year: number, data: Metric[] = []): Metrics => {
  const currentYear = calcMetrics(filterMetricsByYear(data, year));
  const previousYear = calcMetrics(filterMetricsByYear(data, year - 1));

  const successRate = currentYear.length !== 0 ? _.flow(
    _.filter(['is_out_of_date', false]),
    _.size,
    (success) => success / currentYear.length,
  )(currentYear) : 1;

  const successRatePreviousYear = previousYear.length !== 0 ? _.flow(
    _.filter(['is_out_of_date', false]),
    _.size,
    (success) => success / previousYear.length,
  )(previousYear) : 1;

  const successRateVariation = successRate - successRatePreviousYear;

  const outOfDateByMonth = _.flow(
    _.groupBy((metric: CalcMetric) => metric.created_at.month()),
    _.mapValues(_.flow(
      _.filter(['is_out_of_date', true]),
      _.size,
    )),
  )(currentYear);

  const criticalMonths = _.flow(
    _.values,
    _.max,
    (maxMonths) => _.flow(
      _.toPairs,
      _.filter(([, count]: [number, number]) => count === maxMonths),
      _.map(([month]) => month),
    )(outOfDateByMonth),
  )(outOfDateByMonth);

  const outofDateByType = _.flow(
    _.groupBy('metric_code'),
    _.mapValues(_.flow(
      _.filter(['is_out_of_date', true]),
      _.size,
    )),
  )(currentYear);

  const criticalRequestType = _.flow(
    _.values,
    _.max,
    (maxCount) => _.flow(
      _.toPairs,
      _.filter(([, count]: [string, number]) => count === maxCount),
      _.map(([type]) => type),
      _.head,
    )(outofDateByType),
  )(outofDateByType) || '';

  const metricCodes = _.flow(
    _.map('metric_code'),
    _.uniq,
    _.sortBy((code: string) => code),
  )(currentYear);

  const metricCategories = _.flow(
    _.map('metric_category'),
    _.uniq,
    _.sortBy((category: string) => category),
  )(currentYear);

  return {
    currentYear,
    previousYear,
    metricCodes,
    metricCategories,
    stats: {
      successRate,
      successRateVariation,
      totalRequests: currentYear.length,
      criticalRequestType,
      outOfDate: _.flow(
        _.filter(['is_out_of_date', true]),
        _.size,
      )(currentYear),
      criticalMonths: _.map(
        (month: number) => momentum().month(month).format('MMM').replace('.', ''),
      )(criticalMonths).slice(0, 3).join('/'),
      meanOverdueDays: _.flow(
        _.filter(['is_out_of_date', true]),
        _.map('overdue_days'),
        _.mean,
        (mean) => (Number.isNaN(mean) ? 0 : Math.round(mean * 100) / 100),
      )(currentYear),
    },
  };
};

export type { Metric, MetricsGraphicData, Metrics };

export default buildMetrics;
