import { createStore, EasyPeasyConfig, Store } from 'easy-peasy';
import _ from 'lodash/fp';

import clientStore, { ClientStore } from './models';
import { useClientState, useClientActions } from './hooks';
import {
  ClientRegisterParams,
  ClientUpdateParams,
  defaultClientRegisterParams,
} from './models/client';
import { FraudParams, IncidentParams, MessageParams } from './models/contact';
import { PowerByMonth } from './models/consumption';
import { Breakdown } from './models/contracts';
import { RequestAppointment } from './models/requests';
import { ConcentratorData } from './models/icp';

const buildClientStore = (overrideConfig: EasyPeasyConfig = {}): Store<ClientStore> => {
  const defaultConfig = {
    injections: {},
  };

  const config = _.defaultsDeep(defaultConfig, overrideConfig);

  if (process.env.NODE_ENV === 'development') {
    return createStore<ClientStore>(clientStore, {
      devTools: true,
      ...config,
    });
  }

  return createStore<ClientStore>(clientStore, config);
};

export {
  useClientState,
  useClientActions,
  defaultClientRegisterParams,
};

export type {
  ClientRegisterParams,
  ClientUpdateParams,
  FraudParams,
  IncidentParams,
  MessageParams,
  PowerByMonth,
  Breakdown,
  RequestAppointment,
  ConcentratorData,
};

export default buildClientStore();
