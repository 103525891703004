const ROLES = [
  'ADMIN',
  'USER',
] as const;

type Role = typeof ROLES[number];

const REQUESTS = [
  'CUPS',
  'CAU',
  'SAD',
  'SARA',
  'SARG',
  'SAyC',
] as const;

type Request = typeof REQUESTS[number];

const SECTIONS = [
  'scheduled-cuts',
  'non-payment-cuts',
  'iclients',
  'authorizations',
  'managers',
  'metrics',
] as const;

type Section = typeof SECTIONS[number];

const EDITION = [
  'texts',
  'links',
  'nodal',
  'legal',
] as const;

type Edition = typeof EDITION[number];

const CONTACTS = [
  'message',
  'ifraud',
  'incidents',
] as const;

type Contact = typeof CONTACTS[number];

type Manager = {
  id?: number;
  createdAt?: string;
  name: string;
  email: string;
  role: Role;

  requests: Request[];
  distributors: string[];
  sections: Section[];
  edition: Edition[];
  contacts: Contact[];
}

interface IManager extends Manager {
  edit: (editionType: Edition) => boolean;
  request: (requestType: Request) => boolean;
  section: (sectionType: Section) => boolean;
  editable: boolean;
  requestable: boolean;
  admin: boolean;
}

export {
  ROLES,
  REQUESTS,
  SECTIONS,
  EDITION,
  CONTACTS,
};

export type {
  Role,
  Request,
  Section,
  Edition,
  Contact,
  Manager,
  IManager,
};
