import React, { createContext, useContext } from 'react';

interface SelectedContractContextType {
    cupsType: string | undefined;
    contractId: string | undefined;
    setSelectedContractId: (id: string) => void;
  }

const SelectedContractContext = createContext<SelectedContractContextType | null>(null);

export const useSelectedContract = () => {
  const context = useContext(SelectedContractContext);
  if (!context) {
    throw new Error('useSelectedContract must be used within a SelectedContractProvider');
  }
  return context;
};

interface SelectedContractProviderProps {
  children: React.ReactNode;
  value: SelectedContractContextType;
}

export const SelectedContractProvider: React.FC<SelectedContractProviderProps> = (
  { children, value },
) => (
  <SelectedContractContext.Provider value={value}>
    {children}
  </SelectedContractContext.Provider>
);
