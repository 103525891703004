import {
  action, Action, thunk, Thunk,
} from 'easy-peasy';
import { buildDocument, CLIENT_TYPES } from 'models';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

import { ClientRegisterParams, ClientUpdateParams } from './types';

const defaultClientRegisterParams: ClientRegisterParams = {
  type: CLIENT_TYPES[0],
  name: '',
  email: '',
  confirmEmail: '',
  identityNumber: '',
  selfie: buildDocument('selfie', ''),
  front: buildDocument('front-id', ''),
  back: buildDocument('back-id', ''),
  powerOfAttorney: buildDocument('power-of-attorney', ''),
  documents: [],
  address: '',
  postalCode: '',
  municipality: '',
  province: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  acceptance: false,
};

interface ClientModel {
  create: Thunk<ClientModel, ClientRegisterParams>;
  resend: Thunk<ClientModel, string>;
  validate: Thunk<ClientModel, string>;
  update: Thunk<ClientModel, ClientUpdateParams>;
  // Add these new properties
  validationAttempted: boolean;
  setValidationAttempted: Action<ClientModel, boolean>;
}

const clientModel: ClientModel = {
  validationAttempted: false,

  create: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/registration', payload);
    return handleResponse(response);
  }),

  validate: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    if (state.validationAttempted) {
      return 'Validation already attempted';
    }

    const response = await API.put(clients)('/email-validation', { token: payload });
    actions.setValidationAttempted(true);
    return handleResponse(response);
  }),

  resend: thunk(async (_actions, payload) => {
    const response = await API.get(clients)(`/email-validation/${payload}`);
    return handleResponse(response);
  }),

  update: thunk(async (_actions, payload, { getStoreActions }) => {
    // @ts-ignore
    const { initUser } = getStoreActions().session;
    const response = await API.put(clients)('/profile', payload);
    return handleResponse(response, initUser);
  }),
  setValidationAttempted: action((state) => {
    state.validationAttempted = true;
  }),
};

export { defaultClientRegisterParams };

export type { ClientRegisterParams, ClientUpdateParams, ClientModel };

export default clientModel;
