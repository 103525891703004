import { useClientState } from 'clients-app/store';
import { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { Link } from 'react-router-dom';
import { ROUTES } from 'clients-app/libs';
import { H2 } from 'components/typographies';
import { t } from 'libs';
import { Icon } from 'components/icons';

import MenuItem from './MenuItem';

const Aside: FunctionComponent = () => {
  const company = useClientState((state) => state.company.item);
  const user = useClientState((state) => state.session.user);
  const first = _.first(user.name);

  return (
    <aside className="w-full max-w-[230px] xl:max-w-[292px] hidden lg:block bg-header-negative relative shadow-right px-5 full:pl-16 full:pr-8">
      <div className="absolute top-0 w-[calc((100vw-1440px)/2)] h-full right-full bg-header-negative" />
      <div className="sticky w-full top-28 xl:top-32">
        <Link to={ROUTES.profile.index} className="flex items-center w-full mb-12 group">
          <div className="flex items-center justify-center w-12 h-12 mr-3 rounded-md bg-backdrop-secondary">
            <H2>{first}</H2>
          </div>
          <p className="mr-[18px] text-s-lg">{t('clients.pages.profile')}</p>
          <Icon name="circle-angle-right" className="text-content-interaction" animate />
        </Link>
        <div className="full">
          <MenuItem
            icon="lightbulb"
            label={t('clients.pages.consumption')}
            to={ROUTES.consumption.index}
            match={ROUTES.consumption.index}
          />
          <MenuItem
            icon="bolt"
            label="Mi contador"
            to={ROUTES.meter.index}
            match={ROUTES.meter.index}
          />
          <MenuItem
            icon="document"
            label={t('clients.pages.requests')}
            to={ROUTES.requests.index}
            match={ROUTES.requests.index}
          />
          {company.isPremium && (
            <MenuItem
              icon="bad"
              label={t('clients.pages.breakdowns')}
              to={ROUTES.breakdowns.index}
            />
          )}
        </div>
      </div>
    </aside>
  );
};

export default Aside;
