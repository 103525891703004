import _ from 'lodash/fp';
import { AxiosResponse } from 'axios';

import { Callback } from './types';

const isValidResponse = _.flow(
  _.get('status'),
  _.inRange(200, 300),
);

const handleResponse = <T>(r: AxiosResponse, cb?: Callback<T>): string | boolean => {
  if (!isValidResponse(r)) return r.data;
  if (!_.isUndefined(cb)) cb(r.data);

  return true;
};

const handleData = <T>(r: AxiosResponse): string | T => {
  if (!isValidResponse(r)) return r.data;

  return r.data;
};

export { handleResponse, handleData };
