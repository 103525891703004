import { Icon, IconName } from 'components/icons';
import { Caption } from 'components/typographies';
import { useIsMatch } from 'hooks';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

interface Props {
  label: string;
  to: string;
  icon: IconName;
  match?: string;
  onClick?: () => void;
}

const TabItem: FunctionComponent<Props> = (
  {
    icon,
    label,
    to,
    match,
    onClick,
  }: Props,
) => {
  const isMatch = useIsMatch(match ?? to, false);
  const LINK = classnames('flex flex-1 flex-col items-center px-1', {
    'text-content-disabled': !isMatch,
    'text-primary': isMatch,
  });
  const LABEL = classnames('truncate max-w-full', {
    'text-content-disabled': !isMatch,
    'text-content font-bold': isMatch,
  });

  const handleClick = (_e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link to={to} className={LINK} onClick={handleClick}>
      <Icon name={icon} className="mb-0.5" />
      <Caption className={LABEL}>{label}</Caption>
    </Link>
  );
};

export default TabItem;
