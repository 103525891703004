import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import {
  API, handleResponse,
} from 'libs';
import {
  buildMetrics, Metric, Metrics, RequestTypes,
} from 'models';
import { saveAs } from 'file-saver';

import managers from '../../managers';

import { MetricsParams } from './types';

interface MetricsModel {
  year: string;
  distributor: string;
  requestType: RequestTypes | null;
  category: string | null;
  code: string | null;
  years: string[];
  metrics: Metrics;

  init: Action<MetricsModel, Metric[]>;
  initFilters: Action<MetricsModel, number[]>;

  index: Thunk<MetricsModel, MetricsParams>;
  filters: Thunk<MetricsModel>;
  export: Thunk<MetricsModel, MetricsParams>;

  setYear: Action<MetricsModel, string>;
  setDistributor: Action<MetricsModel, string>;
  setRequestType: Action<MetricsModel, RequestTypes>;
  setCategory: Action<MetricsModel, string | null>;
  setCode: Action<MetricsModel, string | null>;
  buildExport: Action<MetricsModel, string>;
}

const metricsModel: MetricsModel = {
  year: '',
  distributor: '',
  requestType: null,
  category: null,
  code: null,
  years: [],
  metrics: buildMetrics(new Date().getFullYear()),

  init: action((state, payload) => {
    state.metrics = buildMetrics(Number(state.year), payload);
    state.category = null;
    state.code = null;
  }),

  initFilters: action((state, payload) => {
    state.years = payload.map(String);
  }),

  index: thunk(async (actions, payload) => {
    const response = await API.get(managers)('/metrics', { params: payload });
    return handleResponse(response, actions.init);
  }),

  filters: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/metrics/years');
    return handleResponse(response, actions.initFilters);
  }),

  export: thunk(async (actions, payload) => {
    const response = await API.get(managers)('/metrics/export', { params: payload });
    return handleResponse(response, actions.buildExport);
  }),

  setYear: action((state, payload) => {
    state.year = payload;
  }),

  setDistributor: action((state, payload) => {
    state.distributor = payload;
  }),

  setRequestType: action((state, payload) => {
    state.requestType = payload;
  }),

  setCategory: action((state, payload) => {
    state.category = payload;

    if (payload) {
      state.code = state.metrics.metricCodes.find(
        (code) => code.startsWith(payload),
      ) ?? null;
    } else {
      state.code = null;
    }
  }),

  setCode: action((state, payload) => {
    state.code = payload;
  }),

  buildExport: action((state, payload) => {
    const byteChars = window.atob(payload);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i += 1) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, `metricas_${state.year}_${state.requestType}.xlsx`);
  }),
};

export type { MetricsModel };

export default metricsModel;
